.optionsSwiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.optionsSwiper .swiper-pagination {
  left: 10px;
  right: unset;
}

.swiper-pagination-bullet-active {
  background-color: #000;
}
